<template>
  <b-container
      class="bv-example-row mb-3"
      fluid
  >
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <hr v-if="!spinner">
    <div v-if="!spinner">
      <b-card-actions
          class="card-header-row-btn"
      >
        <b-row class="mt-50" v-if="width > 740">
          <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
            <b-col cols="12">
              <h4 class="text-white d-inline-block">{{ $store.getters.faturaListReturn.title }}</h4>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton border-0 float-right"
                  to="/"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="GridIcon"
                    class="mr-50"
                />
                Dashboard
              </b-button>
              <b-button
                  v-b-modal.normal-fatura-olustur
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="myButton mr-1 border-0 float-right"
                  :style="{backgroundColor:$store.getters.cardTitleButton}"
              >
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                Fatura Oluştur
              </b-button>
            </b-col>
          </div>
        </b-row>
        <b-row class="mt-1" v-else>
          <b-col cols="12">
            <h4 class="text-dark d-inline-block">{{ $store.getters.faturaListReturn.title }}</h4>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                class="myButton border-0 float-right"
                to="/"
                :style="{backgroundColor:$store.getters.cardTitleButton}"
            >
              <feather-icon
                  icon="GridIcon"
                  class="mr-50"
              />
              Dashboard
            </b-button>
            <b-button
                v-b-modal.normal-fatura-olustur
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="bgInfo"
                class="myButton mr-1 border-0 float-right"
                :style="{backgroundColor:$store.getters.cardTitleButton}"
            >
              <feather-icon
                  icon="EditIcon"
                  class="mr-50"
              />
              Fatura Oluştur
            </b-button>
          </b-col>
        </b-row>
        <hr class="mt-4 mb-1">
        <b-row class="mb-2 mt-sm-4 mt-4 mt-lg-1">
          <b-col lg="6" sm="12" cols="12" class="d-inline-table">
            <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
              <b-input-group
                  size="sm"
                  prepend="Gösterilecek kayıt sayısı"
                  class="unPrint per-page"
              >
                <b-form-select
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                >
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12" cols="12" class="d-flex justify-content-end">
            <b-input-group size="sm" class="search-bar" :class="width <= 992 ? ['minW100','mt-1']:''">
              <b-input-group-prepend>
                <b-button
                    variant="outline-primary"
                    size="sm"
                    disabled
                    class="search-input mb-0"
                >
                  Ara
                </b-button>
              </b-input-group-prepend>
              <b-form-input id="filterInput" v-model="filter" type="search" autocomplete="off"/>
            </b-input-group>
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end mt-1">
            <b-input-group class="justify-content-end date-input-group">
              <b-input-group-prepend>
                <flat-pickr
                    v-model="baslangic"
                    class="form-control"
                />
              </b-input-group-prepend>
              <b-button
                  variant="outline-primary"
                  disabled
                  class="disabledColor height-80"
              >
                <feather-icon icon="CalendarIcon"/>
              </b-button>
              <b-input-group-append>
                <flat-pickr
                    v-model="bitis"
                    class="form-control mr-2"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-table
            striped
            hover
            class="table-responsive"
            empty-text="Veri yok"
            :items="salaryListItems"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            stacked="sm"
            show-empty
            small
            @filtered="onFiltered"
        >
          <template #cell()="row">
            #{{ row.item.kayitID }}
          </template>
          <template #cell(faturaNo)="row">
            <p class="text-success fatur">
              {{ row.item.faturaNo }}
            </p>
            <b-badge
                variant="bgSuccess"
                style="font-weight: bold"
            >
              ETTN: {{ row.item.faturaID }}
            </b-badge>
          </template>
          <template #cell(faturaUnvan)="row">
            <div style="display: grid!important">
              <b-badge
                  v-if="row.item.faturaUnvan"
                  variant="bgDanger"
                  style="font-weight: bold"
              >
                {{ row.item.faturaUnvan }}
              </b-badge>
              <b-badge
                  v-if="row.item.adi"
                  variant="bgPrimary"
                  class="mt-50"
                  style="font-weight: bold"
              >
                {{ row.item.adi + row.item.soyAdi }}
              </b-badge>
            </div>
          </template>
          <template #cell(vergiNumarasi)="row">
            <p class="text-success">
              {{ row.item.vergiNumarasi }}
            </p>
            <p class="text-danger">
              {{ row.item.vergiDairesi }}
            </p>
          </template>
          <template #cell(toplamTutar)="row">
            <b-badge
                variant="bgInfo"
                style="font-weight: bold"
            >
              {{ row.item.toplamTutar }} TL
            </b-badge>
          </template>
          <template #cell(kayitTarih)="row">
            <p class="text-danger">
              {{ row.item.kayitTarih }}
            </p>
          </template>
          <template #cell(actions)="row">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="white-space: nowrap !important"
                variant="outline-primary"
                size="sm"
                class="mr-1"
                @click="faturaShow(row)"
            >
              <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
              />
              <span class="align-middle">Faturayı Görüntüle</span>
            </b-button>
          </template>
        </b-table>
        <b-row class="mt-3 alignItemsCenter">
          <b-col cols="6">
            <p class="text-muted">
              Toplam {{ totalRows }} kayıt gösteriliyor
            </p>
          </b-col>
          <b-col cols="6">
            <b-pagination
                v-model="currentPage"
                align="end"
                :total-rows="totalRows"
                :per-page="perPage"
                size="sm"
                class="my-0 pagination-info"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
  </b-container>
</template>

<script>
import {
  BRow,
  BCol,
  BContainer,
  BTable,
  BBadge,
  BForm,
  BButton,
  BCard,
  BBreadcrumb,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BSpinner,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BFormCheckbox,
  BModal,
  VBModal,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
import { useWindowSize } from '@vueuse/core'

flatpickr.localize(Turkish)
flatpickr(flatPickr)
export default {
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
  components: {
    BCardActions,
    BRow,
    BCol,
    BContainer,
    BTable,
    BBadge,
    BForm,
    BInputGroupPrepend,
    BButton,
    BCard,
    BBreadcrumb,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BSpinner,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
    BFormCheckboxGroup,
    BFormCheckbox,
    BModal,
    flatPickr,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      spinner: true,
      baslangic: '',
      bitis: '',
      fields: [
        {
          key: 'kayitID',
          label: '',
          sortable: true,
          sortDirection: 'desc',
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            width: '1%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'faturaNo',
          label: 'FATURA NO',
          class: 'text-center',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            width: '29%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'faturaUnvan',
          label: 'FATURA UNVANI',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            width: '15%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'vergiNumarasi',
          label: 'VERGİ NUMARASI',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            width: '10%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'toplamTutar',
          label: 'TOPLAM TUTAR',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            width: '10%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'kayitTarih',
          label: 'İŞLEM TARİHİ',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          thStyle: {
            width: '15%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            width: '10%',
            backgroundColor: this.$store.getters.tableTitle || '#0ea9d7',
            color: 'white'
          },
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100, {
        value: 100,
        text: 'Tümünü Göster'
      }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      choose: [],
    }
  },
  computed: {
    salaryListItems() {
      return this.$store.getters.faturaListReturn.liste
    },
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
    },
  },
  watch: {
    baslangic(newVal) {
      const today = new Date()
      const yil = today.getFullYear()
      const gun = String(today.getDate())
          .padStart(2, '0')
      const ay = String(today.getMonth() + 1)
          .padStart(2, '0')
      const mevcutGun = `${yil}-${ay}-${gun}`
      this.$store
          .dispatch('faturaListFiltre', {
            baslangic: newVal,
            bitis: this.bitis || mevcutGun,
          })
          .then(res => {
            this.totalRows = res.liste.length
          })
    },
    bitis(newVal) {
      const today = new Date()
      const yil = today.getFullYear()
      const gun = String(today.getDate())
          .padStart(2, '0')
      const ay = String(today.getMonth() + 1)
          .padStart(2, '0')
      const mevcutGun = `${yil}-${ay}-${gun}`
      this.$store
          .dispatch('faturaListFiltre', {
            baslangic: this.baslangic || mevcutGun,
            bitis: newVal,
          })
          .then(res => {
            this.totalRows = res.liste.length
          })
    },
  },
  created() {
    this.$store
        .dispatch('faturaList')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          this.totalRows = res.liste.length
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                },
                { position },
            )
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearFaturaList')
  },
  methods: {
    faturaShow(veri) {
      this.$store
          .dispatch('faturaDetay', { id: veri.item.faturaID })
          .then(res => {
            const Y = '<html>'
            const X = res
            console.log('X', X)
            const Z = X.slice(X.indexOf(Y) + Y.length)
            const myWindow = window.open(
                'about:blank',
                'print_popup',
                'width=1000,height=800',
            )
            myWindow.document.write(Z)
            myWindow.document.write(`
                <script>
                    document.addEventListener('DOMContentLoaded', function() {
                        const imgs = document.querySelectorAll('img')
                        imgs.forEach(img => {
                            if (
                                !img.getAttribute('src') &&
                                img.style.width === '230px' &&
                                img.style.height === '150px'
                            ) {
                                img.style.display = 'none'
                            }
                        })
                    })
                <\/script>
            `)
            myWindow.document.close()
            myWindow.print()
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    saveEvident(item) {
      this.choose = item
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
}

.table.table-sm th,
.table.table-sm td {
  text-align: start !important;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>

@media screen and (max-width: 3000px) {
  .per-page {
    width: 30% !important;
  }
}

@media screen and (max-width: 2000px) {
  .per-page {
    width: 35% !important;
  }
}

@media screen and (max-width: 1800px) {
  .per-page {
    width: 40% !important;
  }
}

@media screen and (max-width: 1600px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .per-page {
    width: 50% !important;
  }
}

@media screen and (max-width: 1100px) {
  .per-page {
    width: 55% !important;
  }
}

@media screen and (max-width: 1000px) {
  .per-page {
    width: 65% !important;
  }
}

@media screen and (max-width: 900px) {
  .per-page {
    width: 75% !important;
  }
}

@media screen and (max-width: 800px) {
  .per-page {
    width: 90% !important;
  }
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.mr-12 {
  margin-right: 12px !important;
  background-color: #2e83c9 !important;
}

.myHr {
  width: 60% !important;
}

.badge-primary {
  background-color: rgba(115, 103, 240, 0.12);
  color: #7367f0 !important;
}

.spinners {
  position: absolute;
  margin-left: 47%;
  margin-top: 15%;
  width: 3rem;
  height: 3rem;
}

.myButton {
  color: white !important;
}

.myButton:hover {
  color: white !important;
}

a {
  color: #408dcc !important;
}

a:hover {
  color: #6d62e4 !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.row {
  justify-content: space-between;
}

.card-header {
  background-color: transparent !important;
}

.minW100 {
  min-width: 100% !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none;
  border-radius: 0.357rem;
}

.disabledColor {
  border-color: #cbc1eb !important;
}

.disabledColor svg {
  transform: translate(0px, -4px) !important;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.col-4 {
  padding-right: 0;
  padding-left: 0;
}

.fatur {
  margin-bottom: 0.4rem !important;
}

.col-sm-3,
.col-md-3 {
  padding-right: 0;
  padding-left: 0;
}

.alignItemsCenter {
  align-items: center !important;
  margin-top: 1.5rem !important;
}

hr {
  border-top: 1px solid #e5e2ef;
}

.table.table-sm th,
.table.table-sm td {
  padding: 0.4rem 0.4rem !important;
}

.search-input {
  border-color: #c3baba !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.flatpickr-input[readonly], [dir] .flatpickr-input ~ .form-control[readonly], [dir] .flatpickr-human-friendly[readonly] {
  //height: 80%;
}

@media screen and (min-width: 1391px) {
  .height-80 {
    //height: 80% !important;
  }
}

.form-control:disabled, [dir] .form-control[readonly] {
  //height: 80%;
}

.disabledColor svg {
  //transform: translate(0px, -5px) !important;
}

.d-inline-table {
  display: inline-table !important;
}
</style>
